import Vue from 'vue'
import Vuex from 'vuex'

import { IAlertModule } from '@/modules/alert/store'
import { ICodeModule } from '@/modules/code/store'
import { ICommentModule } from '@/modules/comment/store'
import { IDiagramModule } from '@/modules/diagram/store'
import { IDomainModule } from '@/modules/domain/store'
import { IEditorModule } from '@/modules/editor/store'
import { IFlowModule } from '@/modules/flow/store'
import { IInterstitialModule } from '@/modules/interstitial/store'
import { ILandscapeModule } from '@/modules/landscape/store'
import { IModelModule } from '@/modules/model/store'
import { IOrganizationModule } from '@/modules/organization/store'
import { IRenderModule } from '@/modules/render/store'
import { IRouteModule } from '@/modules/route/store'
import { IShareModule } from '@/modules/share/store'
import { ITagModule } from '@/modules/tag/store'
import { IUserModule } from '@/modules/user/store'
import { IVersionModule } from '@/modules/version/store'

Vue.use(Vuex)

export interface IStore {
  alert: IAlertModule
  comment: ICommentModule
  diagram: IDiagramModule
  domain: IDomainModule
  editor: IEditorModule
  flow: IFlowModule
  github: ICodeModule
  interstitial: IInterstitialModule
  landscape: ILandscapeModule
  model: IModelModule
  organization: IOrganizationModule
  render: IRenderModule
  route: IRouteModule
  share: IShareModule
  tag: ITagModule
  user: IUserModule
  version: IVersionModule
}

const store = new Vuex.Store<IStore>({})

if (window.Cypress) {
  window.store = store
}

export default store
