
import Vue from 'vue'
import Component from 'vue-class-component'

import * as analytics from '@/helpers/analytics'

@Component({
  name: 'PageNotFound'
})
export default class extends Vue {
  mounted () {
    analytics.pageNotFoundScreen.track(this)
  }
}
