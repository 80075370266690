import type * as QuillType from 'quill'
import QuillCore from 'quill/core'
import Blockquote from 'quill/formats/blockquote'
import Bold from 'quill/formats/bold'
import CodeBlock, { Code as InlineCode } from 'quill/formats/code'
import Header from 'quill/formats/header'
import Image from 'quill/formats/image'
import Indent from 'quill/formats/indent'
import Italic from 'quill/formats/italic'
import Link from 'quill/formats/link'
import List from 'quill/formats/list'
import Script from 'quill/formats/script'
import Strike from 'quill/formats/strike'
import Table from 'quill/modules/table'
import Toolbar from 'quill/modules/toolbar'
import Snow from 'quill/themes/snow'
import QuillMagicUrl from 'quill-magic-url'

const Quill = QuillCore as typeof QuillType.default

Quill.register({
  'formats/blockquote': Blockquote,
  'formats/bold': Bold,
  'formats/code': InlineCode,
  'formats/code-block': CodeBlock,
  'formats/header': Header,
  'formats/image': Image,
  'formats/indent': Indent,
  'formats/italic': Italic,
  'formats/link': Link,
  'formats/list': List,
  'formats/script': Script,
  'formats/strike': Strike,
  'modules/magicUrl': QuillMagicUrl,
  'modules/table': Table,
  'modules/toolbar': Toolbar,
  'themes/snow': Snow
})

export type QuillInstance = QuillType.Quill

export default Quill
