import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { theme } from '@icepanel/app-theme'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import icons from '@/helpers/icons'

import customIcons from '../helpers/custom-icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

const iconDefinitions = [...customIcons, ...icons]
const iconValues: any = {}
iconDefinitions.forEach(definition => {
  library.add(definition as IconDefinition)

  iconValues[`${definition.prefix}-${definition.iconName}`] = {
    component: FontAwesomeIcon,
    props: {
      icon: [definition.prefix, definition.iconName]
    }
  }
})

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: iconValues
  },
  theme
})
