import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import * as env from '@/helpers/env'

export const app = initializeApp(env.FIREBASE_APP_CONFIG)

export const initialLoad = new Promise<void>(resolve => {
  getAuth(app).onAuthStateChanged(() => {
    resolve()
  })
})
