import * as Sentry from '@sentry/vue'
import Vue from 'vue'

import * as env from '@/helpers/env'

const IGNORE = [
  'NetworkError',
  'Network Error',
  'error occurred when fetching the script',
  'xhr poll error',
  'Loading chunk',
  'Failed to update a ServiceWorker for scope',
  'Failed to update the ServiceWorker for scope',
  'ServiceWorker script at',
  'Request aborted',
  'Unauthorized',
  'Organization access denied',
  'Landscape access denied',
  'Organization not found',
  'Landscape not found',
  'permission denied',
  'Avoided redundant navigation to current location'
]

if (process.env.VUE_APP_ICE_ENV?.startsWith('production') || process.env.VUE_APP_ICE_ENV?.startsWith('staging')) {
  Sentry.init({
    Vue,
    autoSessionTracking: false,
    dsn: 'https://dc914665c9a8436b96858258038483cb@o545324.ingest.sentry.io/5667352',
    environment: process.env.VUE_APP_ICE_ENV,
    ignoreErrors: IGNORE,
    profilesSampleRate: 0,
    release: `icepanel@${env.VERSION}`,
    replaysSessionSampleRate: 0,
    tracesSampleRate: 0
  })
}
