import { SocketClientEvents, SocketServerEvents } from '@icepanel/platform-api-client'
import * as io from 'socket.io-client'
import customParser from 'socket.io-msgpack-parser'

import * as env from '@/helpers/env'

import * as mixpanel from './mixpanel'

export type ClientEvents = { [K in keyof SocketClientEvents]: (body: SocketClientEvents[K]['body'], ack?: (err?: string, reply?: SocketClientEvents[K]['reply']) => void) => void }
export type ServerEvents = { [K in keyof SocketServerEvents]: (body: SocketServerEvents[K]['body']) => void }

export type Socket = io.Socket<ServerEvents, ClientEvents>

export const manager = new io.Manager<ServerEvents, ClientEvents>(env.REAL_TIME_URL, {
  autoConnect: false,
  parser: customParser,
  path: '/socket/',
  query: {
    ...mixpanel.headers(),
    'client-version': env.VERSION
  },
  secure: true,
  timeout: 40000,
  transports: ['websocket']
})

manager.on('open', async () => {
  console.log('socket opened')
})
manager.on('close', () => {
  console.log('socket closed')
})

manager.on('error', err => {
  console.error(err)
})
manager.on('reconnect_error', err => {
  console.error(err)
})
manager.on('reconnect_attempt', attempt => {
  console.log(`socket reconnect attempt ${attempt}`)
})
manager.on('reconnect', async () => {
  console.log('socket reconnect')
})

export const createSocket = (opts: io.SocketOptions): Socket => manager.socket('/', opts)
