import Vue from 'vue'
import VueRouter from 'vue-router'

import PageNotFound from '@/views/page-not-found.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      component: PageNotFound,
      meta: {
        title: '404'
      },
      name: 'PageNotFound',
      path: '*'
    }
  ]
})

export const setTitle = (sections: string[] = []) => {
  window.document.title = [...sections, 'IcePanel App'].join(' | ')
}

router.afterEach(to => {
  if (typeof to.meta?.title === 'string') {
    setTitle([to.meta.title])
  } else if (to.meta?.title instanceof Array) {
    setTitle(to.meta.title)
  }
})

export default router
