/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.VUE_APP_ICE_ENV?.startsWith('production') || process.env.VUE_APP_ICE_ENV?.startsWith('staging')) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    cached () {
      console.log('Content has been cached for offline use.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    ready () {
      console.log('App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB')
    },
    registered (registration) {
      console.log('Service worker has been registered and now polling for updates.')

      setInterval(() => {
        registration.update()
      }, 10 * 1000)
    },
    updated (registration) {
      console.log('New content is available; requesting refresh.')
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
    },
    updatefound () {
      console.log('New content is downloading.')
    }
  })
}
