import { mixpanel } from '@icepanel/app-analytics'
import { OpenAPI } from '@icepanel/platform-api-client'

import * as env from '@/helpers/env'

const MIXPANEL_HEADER = 'distinct-id'

export const headers = () => ({
  [MIXPANEL_HEADER]: mixpanel.get_distinct_id()
})

mixpanel.init(env.MIXPANEL_TOKEN, {
  batch_flush_interval_ms: 1000,
  batch_requests: true,
  cross_subdomain_cookie: true,
  debug: env.IS_DEV,
  loaded: () => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      ...headers()
    }
  },
  secure_cookie: true
})

const disabled = window.Cypress
if (disabled) {
  mixpanel.opt_out_tracking()
}

export const identify = (userId: string) => {
  mixpanel.identify(userId)

  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    ...headers()
  }
}

export const reset = () => {
  mixpanel.reset()

  OpenAPI.HEADERS = {
    ...OpenAPI.HEADERS,
    [MIXPANEL_HEADER]: ''
  }
}

window.addEventListener('unload', () => {
  if (!disabled) {
    mixpanel.set_config({
      api_transport: 'sendBeacon'
    })
  }
})
